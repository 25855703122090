import { GridItem } from '@which/seatbelt'

import type { LatestNews } from '../../../../generated/frontend'
import { BasicHeader } from '../../../../shared/components/BasicHeader'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb/Breadcrumb'
import { LatestNewsCards } from '../../../../shared/components/LatestNewsCards/LatestNewsCards'
import { LocalFooter } from '../../../../shared/components/LocalFooter/LocalFooter'
import { articleBodyRenderer } from '../../../../shared/renderers/article-body-renderer'
import type { ArticleCrScamsPageData } from '../../article-page-types'
import { WhichConnectMessage } from '../../components/WhichConnectMessage/WhichConnectMessage'
import styles from './ArticleScams.module.scss'
import { CRScamsJumpLinks } from './components/CRScamsJumpLinks/CRScamsJumpLinks'

export const getCRScamsArticleComponents = (data: ArticleCrScamsPageData) => {
  const {
    consumerRightsFooter,
    breadcrumb,
    consumerRightsNavigation,
    header,
    articleBodyTemplate,
    articleConfig,
    latestNews,
  } = data

  // @TODO: Needs looking at and potentially re-writing tech-debt
  // Original comment: Rewrite "callout" component in articleBodyTemplate to "CRScamsNavigation"
  const scamsArticleBodyTemplate = rewriteArticleBodyTemplate(articleBodyTemplate)

  return {
    ArticleHeader: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <BasicHeader header={header.headline} standfirst={header.standfirst} />
      </GridItem>
    ),
    ArticleBody: () => (
      <GridItem
        className={styles.scamsArticle}
        span={{ medium: 10, large: 10 }}
        columnStart={{ medium: 2, large: 2 }}
      >
        {articleBodyRenderer(scamsArticleBodyTemplate)}
      </GridItem>
    ),
    Breadcrumb: () => <Breadcrumb {...breadcrumb} localNavProps={consumerRightsNavigation} />,
    ConsumerRightsFooter: () => (
      <GridItem>
        <LocalFooter data={consumerRightsFooter} />
      </GridItem>
    ),
    SubVerticalJumpLinks: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <CRScamsJumpLinks />
      </GridItem>
    ),
    CRScamsLatestNews: () => (
      <GridItem span={{ small: 'full-bleed', medium: 'full-bleed', large: 'full-bleed' }}>
        <LatestNewsCards
          name="Latest scam news"
          articles={latestNews?.articles as LatestNews['articles']}
          viewAllData={{ text: 'View all news', href: '/consumer-rights/news/scam-and-fraud' }}
        />
      </GridItem>
    ),
    WhichConnectMessage: () => (
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <WhichConnectMessage showMessage={articleConfig?.whichConnectMessage} />
      </GridItem>
    ),
  }
}

///////// IMPLEMENTATION /////////

// @TODO: Remove any and type properly
const rewriteArticleBodyTemplate = (articleBodyTemplate: any) =>
  articleBodyTemplate.map((item) => {
    if (Array.isArray(item?.children) && item.children.length === 1) {
      const [child] = item.children

      if (child.component === 'Callout') {
        return {
          ...item,
          children: [
            {
              ...child,
              component: 'CRScamsNavigation',
            },
          ],
        }
      }
    }

    return item
  })
