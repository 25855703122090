import { useParams } from 'react-router-dom'
import type { PageContext } from '@which/glide-ts-types'

import { useFeatureValue } from '@growthbook/growthbook-react'

import type { HeroVideo, Tag } from '../../../generated/frontend'
import type { PageParams } from '../../../routes'
import { getDFPScripts } from '../../../shared/dfp'
import { usePageProps } from '../../../shared/usePageProps'
import type { ArticlePageVariantTypes } from '../article-page-types'
import { getNewsArticleDataLayer } from '../article-variants/news-article/data-layer'

export const useArticlePageDfpItems = () => {
  // CTX-011-contextualised-newsletter
  const gbTestValue = useFeatureValue('ctx-011-contextualised-newsletter', null)
  const { collectionSlug } = useParams<PageParams>()
  const { context } = usePageProps()

  return {
    getDfpItems: ({ meta, tags, heroVideo }: DfpParams) => {
      const { taxonomyHierarchy, keywords, contentId } = meta

      const pageInfo = {
        vertical: taxonomyHierarchy?.vertical.slug,
        sub_vertical: taxonomyHierarchy?.subVertical?.slug,
        category: taxonomyHierarchy?.category?.slug,
        content_id: contentId,
        GB_test_value: gbTestValue ?? '',
        ...(Boolean(heroVideo) && { isVideoArticle: true }),
        pageType: collectionSlug ? 'mpg-article' : 'article',
      }

      const pageInfoDfp = context === 'news' ? getNewsArticleDataLayer(pageInfo) : pageInfo

      return [
        ...getDFPScripts(meta.dataLayer, {
          ...pageInfoDfp,
          ...getDFPTagsAndKeywords(tags, keywords, context as PageContext),
        }),
      ]
    },
  }
}

///////// IMPLEMENTATION /////////

type DfpParams = {
  meta: ArticlePageVariantTypes['meta']
  tags: Tag[]
  heroVideo?: HeroVideo
}

const getDFPTagsAndKeywords = (tags: Tag[], keywords: string[], context: PageContext) => {
  const mappedTags = tags?.map((tag) => tag.label) || []
  const tagsFallback = context === 'news' ? [] : keywords

  // News articles should fallback to empty array if tags are empty
  // Tags for all other article types have a keywords fallback
  return {
    keywords,
    tags: tags?.length ? mappedTags : tagsFallback,
  }
}
